"use strict"

Object.defineProperty(exports, "__esModule", {
  value: true,
})

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i]
      descriptor.enumerable = descriptor.enumerable || false
      descriptor.configurable = true
      if ("value" in descriptor) descriptor.writable = true
      Object.defineProperty(target, descriptor.key, descriptor)
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps)
    if (staticProps) defineProperties(Constructor, staticProps)
    return Constructor
  }
})()

var _react = require("react")

var _react2 = _interopRequireDefault(_react)

var _propTypes = require("prop-types")

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function")
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    )
  }
  return call && (typeof call === "object" || typeof call === "function")
    ? call
    : self
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " +
        typeof superClass
    )
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  })
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass)
} /* global Prism */

var PrismCode = (function(_PureComponent) {
  _inherits(PrismCode, _PureComponent)

  function PrismCode() {
    var _ref

    var _temp, _this, _ret

    _classCallCheck(this, PrismCode)

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key]
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          PrismCode.__proto__ || Object.getPrototypeOf(PrismCode)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this._handleRefMount = function(domNode) {
        _this._domNode = domNode
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    )
  }

  _createClass(PrismCode, [
    {
      key: "componentDidMount",
      value: function componentDidMount() {
        this._hightlight()
      },
    },
    {
      key: "componentDidUpdate",
      value: function componentDidUpdate() {
        this._hightlight()
      },
    },
    {
      key: "_hightlight",
      value: function _hightlight() {
        Prism.highlightElement(this._domNode, this.props.async)
      },
    },
    {
      key: "render",
      value: function render() {
        var _props = this.props,
          className = _props.className,
          Wrapper = _props.component,
          children = _props.children

        return _react2.default.createElement(
          Wrapper,
          { ref: this._handleRefMount, className: className },
          children
        )
      },
    },
  ])

  return PrismCode
})(_react.PureComponent)

PrismCode.propTypes = {
  async: _propTypes.PropTypes.bool,
  className: _propTypes.PropTypes.string,
  children: _propTypes.PropTypes.any,
  component: _propTypes.PropTypes.node,
}
PrismCode.defaultProps = {
  component: "code",
}
exports.default = PrismCode
