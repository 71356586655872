var V3_URL = 'https://js.stripe.com/v3';

var injectScript = function injectScript() {
  var script = document.createElement('script');
  script.src = V3_URL;
  var headOrBody = document.head || document.body;

  if (!headOrBody) {
    throw new Error('Expected document.body not to be null. Stripe.js requires a <body> element.');
  }

  headOrBody.appendChild(script);
  return script;
}; // Execute our own script injection after a tick to give users time to
// do their own script injection.


var stripePromise = Promise.resolve().then(function () {
  if (typeof window === 'undefined') {
    // Resolve to null when imported server side. This makes the module
    // safe to import in an isomorphic code base.
    return null;
  }

  if (window.Stripe) {
    return window.Stripe;
  }

  var script = document.querySelector("script[src=\"".concat(V3_URL, "\"], script[src=\"").concat(V3_URL, "/\"]")) || injectScript();
  return new Promise(function (resolve, reject) {
    script.addEventListener('load', function () {
      if (window.Stripe) {
        resolve(window.Stripe);
      } else {
        reject(new Error('Failed to load Stripe.js'));
      }
    });
    script.addEventListener('error', function () {
      reject(new Error('Failed to load Stripe.js'));
    });
  });
});
var loadStripe = function loadStripe() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return stripePromise.then(function (maybeStripe) {
    return maybeStripe ? maybeStripe.apply(void 0, args) : null;
  });
};

export { loadStripe };
